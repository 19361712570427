import React from "react"
import {Link} from "gatsby"
import barcodeSvg from "../images/icons/barcode.svg"

/**
 *
 */
class DailyDealsCard extends React.Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {isClient: false};
        this.resolveDow();
    }

    /**
     * Running this to re-hydrate the component
     * https://github.com/gatsbyjs/gatsby/issues/17914
     */
    componentDidMount() {
        this.setState({isClient: true});
    }

    /**
     *
     */
    handleTakeMeToMyLocalStoreClick = () => {
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
                event: "click-button-take-me-to-my-local-store"
            });
        }
    };

    /**
     *
     * @param dow
     * @returns {boolean}
     */
    matchesDOW(dow) {
        return dow === this.dowNow;
    }

    /**
     *
     * @param dow
     * @returns {string}
     */
    getOrderClass(dow) {
        const offset = 1;
        let newOrder = 7 - this.dowNow + dow + offset;
        newOrder = (newOrder <= 6) ? newOrder : (newOrder - 6 - offset);
        newOrder = (newOrder > 6) ? newOrder - 6 - offset : newOrder;

        return 'order-' + newOrder;
    }

    /**
     *
     */
    resolveDow() {
        const now = new Date();
        this.dowNow = now.getDay();
    }

    /**
     *
     */
    handleGetCouponClick = () => {
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
                event: "click-button-get-coupon"
            });
        }
    };

    /**
     *
     * @returns {XML}
     */
    render() {
        return (
            <div className="daily-deals-card pt-3 pb-4" key={this.state.isClient}>
                <h1 className="text-center text-md-left px-3 px-md-0">Get Your Coupon for These Amazing Daily
                    Deals!</h1>

                <div className="row mb-3">
                    <div className={"col-12 col-md-6 " + this.getOrderClass(0) + " mb-md-2"}>
                        <div
                            className={"card card-daily-deal mb-2 " + (this.matchesDOW(0) ? "dow-active" : "dow-inactive")}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-7">
                                        <h2>Sunday</h2>
                                        <p className="small pb-2">Buy any Sandwich Combo Meal, and get a Little John
                                            meal for FREE</p>
                                    </div>
                                    <div className="col-5 text-right">
                                        <div className="cta-to-coupon">
                                            <Link className="btn btn-secondary" to="/daily-deals/sunday"
                                                  onClick={this.handleGetCouponClick}>Get&nbsp;Coupon</Link>
                                            <Link to="/daily-deals/sunday" onClick={this.handleGetCouponClick}><img
                                                src={barcodeSvg} alt=""/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 " + this.getOrderClass(1) + " mb-md-2"}>
                        <div
                            className={"card card-daily-deal mb-2 " + (this.matchesDOW(1) ? "dow-active" : "dow-inactive")}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-7">
                                        <h2>Monday</h2>
                                        <p className="small pb-2">BOGO (buy one get one free)</p>
                                    </div>
                                    <div className="col-5 text-right">
                                        <div className="cta-to-coupon">
                                            <Link className="btn btn-secondary" to="/daily-deals/monday"
                                                  onClick={this.handleGetCouponClick}>Get&nbsp;Coupon</Link>
                                            <Link to="/daily-deals/monday" onClick={this.handleGetCouponClick}><img
                                                src={barcodeSvg} alt=""/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 " + this.getOrderClass(2) + " mb-md-2"}>
                        <div
                            className={"card card-daily-deal mb-2 " + (this.matchesDOW(2) ? "dow-active" : "dow-inactive")}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-7">
                                        <h2>Tuesday</h2>
                                        <p className="small pb-2">BOGO (buy one get one free)</p>
                                    </div>
                                    <div className="col-5 text-right">
                                        <div className="cta-to-coupon">
                                            <Link className="btn btn-secondary" to="/daily-deals/tuesday"
                                                  onClick={this.handleGetCouponClick}>Get&nbsp;Coupon</Link>
                                            <Link to="/daily-deals/tuesday" onClick={this.handleGetCouponClick}><img
                                                src={barcodeSvg} alt=""/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 " + this.getOrderClass(3) + " mb-md-2"}>
                        <div
                            className={"card card-daily-deal mb-2 " + (this.matchesDOW(3) ? "dow-active" : "dow-inactive")}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-7">
                                        <h2>Wednesday</h2>
                                        <p className="small pb-2">Get a Classic Box Lunch for $9.99</p>
                                    </div>
                                    <div className="col-5 text-right">
                                        <div className="cta-to-coupon">
                                            <Link className="btn btn-secondary" to="/daily-deals/wednesday"
                                                  onClick={this.handleGetCouponClick}>Get&nbsp;Coupon</Link>
                                            <Link to="/daily-deals/wednesday" onClick={this.handleGetCouponClick}><img
                                                src={barcodeSvg} alt=""/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 " + this.getOrderClass(4) + " mb-md-2"}>
                        <div
                            className={"card card-daily-deal mb-2 " + (this.matchesDOW(4) ? "dow-active" : "dow-inactive")}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-7">
                                        <h2>Thursday</h2>
                                        <p className="small pb-2">BOGO (buy one get one free)</p>
                                    </div>
                                    <div className="col-5 text-right">
                                        <div className="cta-to-coupon">
                                            <Link className="btn btn-secondary" to="/daily-deals/thursday"
                                                  onClick={this.handleGetCouponClick}>Get&nbsp;Coupon</Link>
                                            <Link to="/daily-deals/thursday" onClick={this.handleGetCouponClick}><img
                                                src={barcodeSvg} alt=""/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 " + this.getOrderClass(5) + " mb-md-2"}>
                        <div
                            className={"card card-daily-deal mb-2 " + (this.matchesDOW(5) ? "dow-active" : "dow-inactive")}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-7">
                                        <h2>Friday</h2>
                                        <p className="small pb-2">Get a Classic Box Lunch for $9.99</p>
                                    </div>
                                    <div className="col-5 text-right">
                                        <div className="cta-to-coupon">
                                            <Link className="btn btn-secondary" to="/daily-deals/friday"
                                                  onClick={this.handleGetCouponClick}>Get&nbsp;Coupon</Link>
                                            <Link to="/daily-deals/friday" onClick={this.handleGetCouponClick}><img
                                                src={barcodeSvg} alt=""/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 " + this.getOrderClass(6) + " mb-md-2"}>
                        <div
                            className={"card card-daily-deal mb-2 " + (this.matchesDOW(6) ? "dow-active" : "dow-inactive")}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-7">
                                        <h2>Saturday</h2>
                                        <p className="small pb-2">Buy any Sandwich Combo Meal, and get a Little John
                                            meal for FREE</p>
                                    </div>
                                    <div className="col-5 text-right">
                                        <div className="cta-to-coupon">
                                            <Link className="btn btn-secondary" to="/daily-deals/saturday"
                                                  onClick={this.handleGetCouponClick}>Get&nbsp;Coupon</Link>
                                            <Link to="/daily-deals/saturday" onClick={this.handleGetCouponClick}><img
                                                src={barcodeSvg} alt=""/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ul className="small pr-5">
                    <li className="mb-2">All discount/promo individual orders must be made in-store</li>
                    <li>All Catering orders must be made over phone, or in store</li>
                </ul>
            </div>
        )
    }
}

export default DailyDealsCard
