import React from "react"
import {Link} from "gatsby"

/**
 *
 */
class CateringCard extends React.Component {
    /**
     *
     */
    handleCallForCateringClick = () => {
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
                event: "click-button-call-for-catering"
            });
        }
    };

    /**
     *
     */
    handleCateringMenuClick = () => {
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
                event: "click-button-catering-menu"
            });
        }
    };

    /**
     *
     * @returns {XML}
     */
    render() {
        return (
            <div className="catering-card pt-3 pb-4">
                <h1 className="text-center text-md-left px-3 px-md-0">Freaky Fast &amp; Budget-Friendly Lunch
                    Catering</h1>

                <p>Order today and <strong className="text-primary">get 20% off</strong> any catering Party Box order
                    above $100
                    <small>(in store only.)</small>
                </p>

                <div className="row">
                    <div className="col-6 pr-1">
                        <a className="btn btn-block btn-primary" href="tel:19252703482"
                           onClick={this.handleCallForCateringClick}>Call for Catering</a>
                    </div>
                    <div className="col-6 pl-1">
                        <Link className="btn btn-block btn-primary" to="/catering"
                              onClick={this.handleCateringMenuClick}>Catering
                            Menu</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default CateringCard
