import React from "react"
import {Link} from "gatsby"

class MenuCard extends React.Component {
    /**
     *
     */
    handleSeeOurMenuClick = () => {
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
                event: "click-button-see-our-menu"
            });
        }
    };

    /**
     *
     * @returns {XML}
     */
    render() {
        return (
            <div className="menu-card pt-3 pb-4">
                <h1 className="text-center text-md-left px-3 px-md-0">Our Menu</h1>

                <p>Browse our selection of sandwiches with all-natural<sup>*</sup> meats and fresh veggies cut in-house
                    every day. Our fresh-baked bread is made right here where you can see it, and our house-made tuna
                    salad is fresh every day. Made with love every single day since 1983.</p>

                <div className="container text-center">
                    <Link className="btn btn-primary" to="/menu" onClick={this.handleSeeOurMenuClick}>See Our
                        Menu</Link>
                </div>
            </div>
        )
    }
}

export default MenuCard
