import React from "react"
import {Link} from "gatsby"
import GoogleMapReact from 'google-map-react';
import jjLogoBlack from "../images/logos/jj-logo-black.png";

/**
 *
 */
class LocationsCard extends React.Component {
    /**
     *
     */
    handleFindStoresNearYouClick = () => {
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
                event: "click-button-find-stores-near-you"
            });
        }
    };

    /**
     *
     * @returns {XML}
     */
    render() {
        const AnyReactComponent = (props) => <span><a href={props.address} target="_blank" rel="noopener noreferrer"><img
            src={jjLogoBlack} width="45" height="45" alt={props.text}/></a></span>;

        const defaultProps = {
            center: {
                lat: 37.979130,
                lng: -122.057990
            },
            zoom: 11
        };

        return (
            <div>
                <div className="container pb-2">
                    <h1 className="text-center text-md-left px-3 px-md-0">Stores Near You</h1>
                </div>

                <div style={{height: '500px', width: '100%'}}>
                    <GoogleMapReact bootstrapURLKeys={{key: 'AIzaSyAXsBGCLBV_h75FIc1Mqp2CBY6eFSPR5I4'}}
                                    defaultCenter={defaultProps.center}
                                    defaultZoom={defaultProps.zoom}>
                        <AnyReactComponent lat="37.97902072204148" lng="-122.058067122176" text="JJ's Concord"
                                           address="//maps.apple.com/?sll=37.97902072204148,-122.058067122176&z=9&q=Jimmy%20John%27s%2C%201110%20Concord%20Ave%20Ste.%2020%2C%20Concord%2C%20CA%2094520&address=Jimmy%20John%27s%2C%201110%20Concord%20Ave%20Ste.%2020%2C%20Concord%2C%20CA%2094520"/>
                        <AnyReactComponent lat="37.93716297960219" lng="-122.02513179808973" text="JJ's Concord #2"
                                           address="//maps.apple.com/?sll=37.93716297960219,-122.02513179808973&z=9&q=Jimmy%20John%27s%2C%20785%20Oak%20Grove%20Rd%2C%20Concord%2C%20CA%2094518&address=Jimmy%20John%27s%2C%20785%20Oak%20Grove%20Rd%2C%20Concord%2C%20CA%2094518"/>
                        <AnyReactComponent lat="37.9272920709125" lng="-122.06121723887861" text="JJ's Walnut Creek"
                                           address="//maps.apple.com/?sll=37.9272920709125,-122.06121723887861&z=9&q=Jimmy%20John%27s%2C%202914%20N%20Main%20St%2C%20Walnut%20Creek%2C%20CA%2094597&address=Jimmy%20John%27s%2C%202914%20N%20Main%20St%2C%20Walnut%20Creek%2C%20CA%2094597"/>
                        <AnyReactComponent lat="37.96227620093857" lng="-121.73105973455662" text="JJ's Brentwood"
                                           address="//maps.apple.com/?sll=37.96227620093857,-121.73105973455662&z=9&q=Jimmy%20John%27s%2C%205601%20Lone%20Tree%20Way%20%23130%2C%20Brentwood%2C%20CA%2094513&address=Jimmy%20John%27s%2C%205601%20Lone%20Tree%20Way%20%23130%2C%20Brentwood%2C%20CA%2094513"/>
                    </GoogleMapReact>
                </div>

                <div className="container text-center pt-4">
                    <Link className="btn btn-primary" to="/locations" onClick={this.handleFindStoresNearYouClick}>Find
                        Stores Near You</Link>
                </div>
            </div>
        )
    }
}

export default LocationsCard
