import React from "react"
import {Link} from "gatsby"

class HeroCard extends React.Component {
    /**
     *
     */
    handleTakeMeToMyLocalStoreClick = () => {
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
                event: "click-button-take-me-to-my-local-store"
            });
        }
    };

    /**
     * https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html
     * @returns {XML}
     */
    render() {
        return (
            <div className="row">
                <div className="col-12 col-md-7 text-center pb-4">
                    <div className="jj-banner mt-4">
                        <div className="even">FRESH BAKED</div>
                        <div className="odd">BREAD</div>
                        <div className="even">ALL NATURAL<sup>*</sup></div>
                        <div className="odd">MEATS</div>
                        <div className="even">HAND SLICED</div>
                        <div className="odd">VEGGIES</div>
                    </div>
                </div>
                <div className="col-12 col-md-5 text-center pb-4">
                    <Link className="btn btn-lg btn-primary" to="/locations" onClick={this.handleTakeMeToMyLocalStoreClick}>Take Me to My Local Store</Link>
                </div>
            </div>
        )
    }
}

export default HeroCard
