import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroCard from "../cards/hero-card"
import CateringCard from "../cards/catering-card"
import DailyDealsCard from "../cards/daily-deals-card"
import MenuCard from "../cards/menu-card"
import LocationsCard from "../cards/locations-card"

/**
 *
 */
class IndexPage extends React.Component {
    /**
     *
     * @returns {XML}
     */
    render() {
        return (
            <Layout>
                <SEO title="Home"/>

                <section className="hero-card-container bg-dark">
                    <div className="container">
                        <HeroCard/>
                    </div>
                </section>

                <section className="catering-card-container">
                    <div className="container">
                        <CateringCard/>
                    </div>
                </section>

                <section className="daily-deals-card-container">
                    <div className="container">
                        <DailyDealsCard/>
                    </div>
                </section>

                <section className="menu-card-container bg-light py-2">
                    <div className="container">
                        <MenuCard/>
                    </div>
                </section>

                <section className="location-card-container py-4">
                    <LocationsCard/>
                </section>
            </Layout>
        )
    }
}

export default IndexPage
